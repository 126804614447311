
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700);

html, body { height: 100%; }
body { margin: 0; font-family: 'Open Sans' }

html, body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    font-size: 15px;
}

ul, li, ol {
    list-style: none;
}